@tailwind base;
@tailwind components;
@tailwind utilities;

@import './gap.scss';

html,
body {
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'Microsoft YaHei', 'WenQuanYi Micro Hei',
    sans-serif;
  overflow-x: hidden;
}

// .h-bg {
//   height: 460px;
// }

@media (max-width:800px) {
  .h-bg {
    height: 200px;
  }
}

@media (min-width:800px) and (max-width:1024px){
  .h-bg {
    height: 240px;
  }
}

@media (min-width:1024px) and (max-width:1280px){
  .h-bg {
    height: 280px;
  }
}

@media (min-width:1280px) and (max-width:1366px){
  .h-bg {
    height: 360px;
  }
}

@media (min-width:1366px) and (max-width:1600px){
  .h-bg {
    height: 400px;
  }
}

@media (min-width:1600px) {
  .h-bg {
    height: 460px;
  }
}

body.desktop .q-tab.q-focusable:focus > .q-focus-helper,
body.desktop .q-tab.q-manual-focusable--focused > .q-focus-helper,
body.desktop .q-tab.q-hoverable:hover > .q-focus-helper {
  opacity: 0;
}

body.desktop .q-tab.q-focusable:focus > .q-tab__content,
body.desktop .q-tab.q-manual-focusable--focused > .q-tab__content,
body.desktop .q-tab.q-hoverable:hover > .q-tab__content {
  color: rgba(22, 163, 74, 1);
}

@import './vue-quill.snow.css';
@import './quill.font.css';

.ql-editor {
  height: auto;
}

.ql-editor p {
  text-indent: 2rem;
}

.ql-editor p.ql-indent-1,
.ql-editor p.ql-indent-2,
.ql-editor p.ql-indent-3,
.ql-editor p.ql-indent-4,
.ql-editor p.ql-indent-5,
.ql-editor p.ql-indent-6,
.ql-editor p.ql-indent-7,
.ql-editor p.ql-indent-8,
.ql-editor p.ql-align-center,
.ql-editor p.ql-align-right {
  text-indent: 0;
}
